import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem } from '@mui/material';
import ChevronDownIcon from '../../../icons/ChevronDown';
import ChevronRightIcon from '../../../icons/ChevronRight';
import Home from '@mui/icons-material/Home';
import Products from '@mui/icons-material/LocalOffer';
import Blogs from '@mui/icons-material/ModeEdit';
import BusinessInfo from '@mui/icons-material/Store';
import Dns from '@mui/icons-material/VpnLock';
import Resources from '@mui/icons-material/Subscriptions';
import Support from '@mui/icons-material/Unarchive';
import ReviewsIcon from '@mui/icons-material/Reviews';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import SupportIcon from '@mui/icons-material/Support';
import Pages from '@mui/icons-material/Pages';
import BillingIcon from '@mui/icons-material/AttachMoney';

const NavItem = (props) => {
  const { active, children, depth, icon, info, open: openProp, path, title, outbound, disabled, ...other } = props;
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const iconSwitcher = (iconName) => {
    switch (iconName) {
      case 'home':
        return <Home color="darkGray" />;
      case 'products':
        return <Products color="darkGray" />;
      case 'blogs':
        return <Blogs color="darkGray" />;
      case 'businessInfo':
        return <BusinessInfo color="darkGray" />;
      case 'dns':
        return <Dns color="darkGray" />;
      case 'resources':
        return <Resources color="darkGray" />;
      case 'support':
        return <Support color="darkGray" />;
      case 'analytics':
        return <SignalCellularAltIcon color="darkGray" />;
      case 'pages':
        return <Pages color="darkGray" />;
      case 'reviews':
        return <ReviewsIcon color="darkGray" />;
      case 'leads':
        return <GroupIcon color="darkGray" />;
      case 'socialMediaManagement':
        return <GroupsIcon color="darkGray" />;
      case 'marketplace':
        return <GridViewRoundedIcon color="darkGray" />;
      case 'support-rescue':
        return <SupportIcon color="darkGray" />;
      case 'billing':
        return <BillingIcon color="darkGray" />;
      default:
        return null;
    }

  };

  const NavButton = () => {
    return (
      <Button
        component={!outbound && path ? RouterLink : null}
        startIcon={iconSwitcher(icon)}
        sx={{
          color: 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          pr: '8px',
          py: '12px',
          textTransform: 'none',
          width: '100%',
          fontSize: '.8rem',
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightBold',
            '& svg': {
              color: 'primary.main'
            }
          }),
          ...(disabled && {
            color: 'lightgray',
            fontWeight: 'fontWeightBold',
            '& svg': {
              color: 'lightgray'
            }
          })
        }}
        variant="text"
        to={!outbound ? path : null}
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
        {info}
      </Button>
    );
  };

  // Branch
  if (children) { // TODO is this condition being triggered anywhere in the app?
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" />
            : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      {outbound ? ( // add <a> wrapper
        // eslint-disable-next-line react/jsx-max-props-per-line
        <a href={path} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
          <NavButton />
        </a>
      ) : (
        <NavButton />
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  outbound: PropTypes.bool,
  disabled: PropTypes.bool
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
